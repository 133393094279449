import React, { useState, useEffect } from "react";
import style from "./Menu.module.css";
import { Link } from "react-router-dom";
import { GoMultiSelect } from "react-icons/go";
import { useLocation } from "react-router-dom";
import MenuItem from "./MenuItem";
function Menu() {
  const [toggleMenu, setToggleMenu] = useState(false);

  const [screenwidth, setscreenWidth] = useState(window.innerWidth);

  const location = useLocation();

  useEffect(() => {
    setToggleMenu(false);
  }, [location]);

  const shouldShow = (test) => {
    console.log(location);
    return location.pathname == test;
  };

  useEffect(() => {
    const changeWidth = () => {
      setscreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, [window.innerWidth]);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <nav className={style.NavMenu}>
      {(toggleMenu || screenwidth > 500) && (
        <ul className={style.MenuItemList}>
          {/* {!shouldShow("/") && (
            <li className={style.MenuItem}>
              <Link to="/">Home</Link>
            </li>
          )} */}

            <MenuItem link="/" display="Home" shouldDisplay={shouldShow}/>
            <MenuItem link="/About" display="About" shouldDisplay={shouldShow}/>           
          {/* {!shouldShow("/About") && (
            <li className={style.MenuItem}>
              <Link to="/About">About</Link>
            </li>
          )} */}
        </ul>
      )}
      <button className={style.button} onClick={toggleNav}>
        <GoMultiSelect />
      </button>
    </nav>
  );
}

export default Menu;
