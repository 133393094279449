import React, { useEffect, useRef, useLayoutEffect } from "react";
import PageComponent from "../components/PageComponent";
import Employment from "../components/Employment/Employment";
import { useLoaderData, json, useParams } from "react-router-dom";
import styles from "./About.module.css";
import Skills from "../components/About/Skills/Skills";
import Timeline from "../components/About/Employment/Timeline";
function About() {
  const employment = useLoaderData();

  const { section } = useParams();

  const meSection = useRef(null);
  const skillsSection = useRef(null);
  const employmentSection = useRef(null);

  const scrollToSection = (element) => {
    

    setTimeout(() => {
      element.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);
  };

  useLayoutEffect(() => {
    if (section != undefined) {


      switch (section.toLowerCase()) {
        case "me":
          scrollToSection(meSection);
          break;
        case "skills":
          scrollToSection(skillsSection);
          break;
        case "employment":
          scrollToSection(employmentSection);
          break;
      }
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [section]);

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.heading}>About HT...</h1>
        </div>
        <section ref={meSection} className={styles.about}>
          <h2>I am passionate about technology.</h2>

          <p>
            My career has taken me through multiple industries where I've been
            able to build upon my skills to improve the systems/processes for
            large scale companies. Reflecting on my employment to date, I can
            see a development of my skills, both technical and interpersonal:
            collaboration, communication, and organisation. This is due to a
            love of learning and applying my skills in different contexts which
            results in a greater impact for the individual businesses. Outside of work, I enjoy being on the sea whether that's jet skiing or kayaking.</p>
        </section>
        <section ref={skillsSection} className={styles.skills}>
          <Skills />
        </section> 
        <section ref={employmentSection} className={styles.employment}>
          <Timeline employment={employment} />
        </section>
      </div>
    </div>
  );
}

export default About;

export async function loader() {
  const response = await fetch("/Data/Employment.json");

  if (!response.ok) {
    // return { isError: true, message: 'Could not fetch events.' };
    // throw new Response(JSON.stringify({ message: 'Could not fetch events.' }), {
    //   status: 500,
    // });
    throw json(
      { message: "Could not fetch events." },
      {
        status: 500,
      }
    );
  } else {
    return response;
  }
}
