import React from "react";

import style from "./Home.module.css";
function Home() {
  return (
    <div className={style.BackGround}>
      <div className={style.Card}>
        <div className={style.Name}>
          <b>Phil `HT` Taylor</b>
        </div>
        <div className="Tag">passionate about technology</div>
      </div>
    </div>
  );
}

export default Home;
