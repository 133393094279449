import React from 'react'
import { Outlet, useNavigation } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';


function Layout() {
  return (
    <>
    
    <Navbar/>
    <main>
        <Outlet />
    </main>
    </>
  )
}

export default Layout