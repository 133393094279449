import React from "react";
import style from "./Employment.module.css";
import { employment } from "../../Data/Employment";
import EmploymentItem from "./EmploymentItem";
import { Link } from "react-router-dom";
import EmploymentCardTimeline from "./EmploymentCardDetails/EmploymentCardTimeline";

function Employment() {
  return (
    <div>
      <div>
        {/* <Link
          to="/About"
          style={{
            color: "black",
            top: 50,
            position: "relative",
            display: "block",
          }}
        >
          
        </Link> */}
      </div>
      <EmploymentCardTimeline />
    </div>
  );
}

export default Employment;
