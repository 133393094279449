import React from "react";
import styles from "./TimelineItem.module.css";
import { Link } from "react-router-dom";

function TimelineItem(props) {
  return (
    <>
      <div
        className={
          `${styles.timeline__item} ` +
          `${props.islast == true ? styles.timeline__last : ""}`
        }
      >
        <img src={props.logo} className={styles.img} />
        <div className={styles.timeline__item__header}>{props.company}</div>
        <div className={styles.timeline__item__sidebar}>
          <div className={styles.datefrom}>{props.dateFrom}</div>
          <div className={styles.dateto}>{props.dateTo}</div>
        </div>
        <div className={styles.timeline__item__main}>
          <ul className={styles.positions}>
            {props.positions.map((p, i) => (
              <li key={i}>{p.role}</li>
            ))}
          </ul>
        </div>
        <div className={styles.navigation__link}>
        <Link to={`/About/Employment/${props.company}/`}>
            Read More
          </Link> 
        </div>
      </div>
    </>
  );
}

export default TimelineItem;
