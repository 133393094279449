import React from "react";
import style from "./CompanyInformation.module.css";

function CompanyInformation(props) {
  return (
    <div className={style.company__wrapper}>
      <div className={style.company__details}>
        <div className={style.companyName}>
          <h1>{props.companyName}</h1>
        </div>
        <div className={style.companyLogo}>
          <img src={props.companyLogo} />
        </div>
      </div>
      <div className={style.dates}>
        <div className={style.datefrom}>{props.dateFrom}</div>
        <div className={style.dateto}>{props.dateTo}</div>
      </div>
      <div>
        <div className={style.positions}>
          <ul>
            {props.positions.map((p, i) => (
              <li key={i}>
                {p.role}{" "}
                {props.positions.length > 1 ? `(` + p.dates + `)` : null}
              </li>
            ))}
          </ul>
        </div>

        {props.paragraphs?.map((p, i) => (
          <p className={style.paragraphs} key={i}>
            {p}
          </p>
        ))}
      </div>
      <div className={style.skills}>
        <h3>Utilising the following technologies:</h3>
        <ul>
          {props.skills.map((s, i) => (
            <li key={i}>{s}</li>
          ))}
        </ul>
      </div>
      <div></div>
    </div>
  );
}

export default CompanyInformation;
