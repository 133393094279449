import React from "react";
import TimelineItem from "./TimelineItem";
import styles from "./Timeline.module.css";

function Timeline(props) {
  return (
   <>
      <h2 className={styles.title}>Employment.</h2>
      <div className={styles.description}>
        Below is an overview of my roles since 2007. If you want to read more details then please click on the `Read More` link at the bottom of each position.
      </div>
      <div className={styles.timeline}>
      {props.employment.map((e, i) => (
        <TimelineItem
          key={i}
          islast={i == props.employment.length - 1}
          company={e.Company}
          dateFrom={e.DateFrom}
          dateTo={e.DateTo}
          positions={e.Positions}
          logo={e.Logo}
        />
      ))}

      
    </div>
    </>
  );
}

export default Timeline;
