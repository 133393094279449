import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/Home";
import Menu from "./components/Menu";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import About, {loader as AboutLoader} from "./pages/About";
import Company from "./components/Employment/Company/Company";
import Layout from "./pages/Layout";
import { Children } from "react";
import AboutLayout from "./pages/AboutLayout";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        { index: true, element: <Home /> },
        {
          path: "About",
          element: <AboutLayout />,
          
          children: [
            { index: true, element: <About />,loader: AboutLoader },
            { index: true, element: <About />,loader: AboutLoader, path:":section" },
            {
              path: "Employment/:CompanyName",
              element: <Company />,
              loader: AboutLoader
            },
          ],
        },
      ],
    },
  ]);

  return (
    <div className="App"> 
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
