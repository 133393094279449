import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useParams, useLoaderData } from "react-router-dom";
import styles from "./Company.module.css";
import CompanyInformation from "./CompanyInformation";

function Company() {
  const { CompanyName } = useParams();
  const companyInformaion = useLoaderData();
  const [company, setCompany] = useState({});

  const [sections, setSections] = useState([]);
  const notfound = <div> notFound</div>;

  const myRefs = useRef([]);
  myRefs.current = [];

  useLayoutEffect(() => {
    let cmp = companyInformaion.find(
      (x) => x.Company.toLowerCase() == CompanyName.toLowerCase()
    );

    setCompany(cmp);

    const companyIndex = companyInformaion
      .map((e) => e.Company.toLowerCase())
      .indexOf(CompanyName.toLowerCase());

    const el = myRefs.current[companyIndex];

    if (el != undefined) {
      
      setTimeout(() => {
        el?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);
    }
  }, [CompanyName]);

  return (
    <div className={styles.companywrapper}>
      {company != null ? (
        <>
          <div className={styles.container}>
            <div className={styles.header}>
              <h1 className={styles.heading}>HT's Roles</h1>
            </div>
            {companyInformaion.map((c, i) => (
              <div
                key={c.Company}
                ref={(el) => (myRefs.current[i] = el)}
              >
               <CompanyInformation companyLogo={c.Logo} companyName={c.Company} paragraphs={c.Paragraphs} positions={c.Positions} dateFrom={c.DateFrom} dateTo={c.DateTo} skills={c.Skills}/>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div> notFound</div>
      )}
    </div>
  );
}

export default Company;
