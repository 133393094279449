import React from "react";
import { Link } from "react-router-dom";
import styles from "./Navigation.module.css";
import * as FaIcons from "react-icons/fa";

function Navigation(props) {
  return (
    <nav
      className={
        `${styles.navigation} ` + `${props.checked ? styles.checked : ""}`
      }
    >
      <div className="navigation-header">
        <h1 className={styles.navigation__heading}>Where to...</h1>
      </div>
      <div>
      <ul className={styles.navigation__list}>
        <li className={styles.navigation__item}>
          <Link to="/" className={styles.navigation__link}>
            Home
          </Link>
        </li>
        <li className={styles.navigation__item}>
          <Link to="/About" className={styles.navigation__link}>
            About
          </Link>           
        </li>
        <ul className={styles.navigation__list}>
            <li className={styles.navigation__item}>
              <Link
                to="/About/Me"
                className={
                  `${styles.navigation__link} ` +
                  `${styles.navigation__Link__Small}`
                }
              >
                About HT
              </Link>
            </li>
            <li className={styles.navigation__item}>
              <Link
                to="/About/Skills"
                className={
                  `${styles.navigation__link} ` +
                  `${styles.navigation__Link__Small}`
                }
              >
                Skills
              </Link>
            </li>
            <li className={styles.navigation__item}>
              <Link
                to="/About/Employment"
                className={
                  `${styles.navigation__link} ` +
                  `${styles.navigation__Link__Small}`
                }
              >
                Employment
              </Link>
            </li>
          </ul>         
      </ul>
      </div>
      <div>
      <ul className={styles.social__icons__list}>
          <li>
          <a href="https://www.linkedin.com/in/phil-ht-taylor-78307315" className={styles.social__link}>
            
              <FaIcons.FaLinkedin />
            </a>
          </li>
          <li>
          <a href="mailto:enquiry@hallstaylor.com" className={styles.social__link}>
              <FaIcons.FaMailBulk />
            </a>
          </li>
        </ul>
        </div>
      <div className={styles.copywrite}>
        <p>&copy; 2023. Phil Taylor</p>
      </div>
    </nav>
  );
}

export default Navigation;
