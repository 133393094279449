import React, { useEffect, useState } from "react";
import styles from "./Skills.module.css";

function Skills() {
  const [loaded, setLoaded] = useState(false);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    fetch("/Data/Skills.json")
      .then((response) => response.json())
      .then((data) => setSkills(data));
  }, []);

  return (
    <div className={styles.skills}>
      
        <h2 className={styles.skills__header}>My Skills.</h2>

        <div className={styles.skills__description}>
          The following are a small selction of my skills:
        </div>
     

      <div className={styles.wrapper}>
        {skills.map((d, i) => (
          <div
            key={i}
            className={styles.skill__item}
            style={d.style}
            alt={d.SkillName}
          >
            {d.SkillName}
          </div>
        ))}
       
      </div>

      {/* {skills.map((d, i) => (
          
          <div>test</div>
        ))} */}
    </div>
  );
}

export default Skills;
